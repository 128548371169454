<template>
  <base-modal
    modal-title="Confirm Logout"
  >
    <template #modalBody>
      <div class="px-2 py-3">
        <div class="font-sm text-center mb-2">
          Are you sure to logout?
        </div>
        <div class="text-center">
          <base-button
            class="mt-2 mb-2 mr-4"
            variant="btn-primary"
            text="Yes"
            @click="emitAcceptedEvent()"
          />
          <base-button
            class="mt-2 mb-2"
            variant="btn-link"
            text="No"
            @click="emitRejectedEvent()"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
    name: 'ConfirmLogoutModal',

    components: {
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    emits: [
        'accepted',
        'rejected'
    ],

    setup (props, { emit }) {
        const emitAcceptedEvent = () => {
            emit('accepted');
        };
        const emitRejectedEvent = () => {
            emit('rejected');
        };

        return {
            emitAcceptedEvent,
            emitRejectedEvent
        };
    }
};
</script>
